
import { defineComponent, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'
import { getIllustrationsPath } from '@/core/helpers/assets'

export default defineComponent({
  name: 'error-404',
  components: {},
  setup () {
    const store = useStore()

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'bg-body')
    })

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'bg-body')
    })

    return {
      getIllustrationsPath
    }
  }
})
